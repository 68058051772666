import { createTheme, ThemeProvider } from "@mui/material/styles";

const contactFormTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          color: "#ED1D65",
        },
      },
    },
  },
});

export default contactFormTheme;
