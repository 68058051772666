import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  mainContainerBox: {
    width: '-webkit-fill-available !important',
    display: 'flex',
    maxWidth: '-webkit-fill-available !important',
    justifyContent: 'center',
    boxShadow: '0 -5px 10px hsl(0deg 0% 0% / 40%)',
    position: 'relative',
    zIndex: 1,
    padding: '0 !important',
    flexGrow: 1,
    // height: 'auto',
    // minHeight: '100vh',
  },

});

export default useStyles;
