import { createTheme, ThemeProvider } from "@mui/material/styles";

// const outerTheme = createTheme({
//     palette: {
//       primary: {
//       },
//     },
//   });

const generalTheme = createTheme({
  typography: {
    body1: {
      fontSize: "24px",
      color: "white",
      textTransform: "math-auto",
      "@media (max-width: 1680px)": {
        fontSize: "24px",
      },
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "12px !important",
      color: "white",
      textTransform: "math-auto",
      "@media (max-width: 1680px)": {
        fontSize: "8px",
      },
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
});

export default generalTheme;
