import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import useStyles from "./Works.styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WorksArt from "./worksections/art/WorksArt";
import WorksVisualDesign from "./worksections/visualdesign/WorksVisualDesign";
import WorksWeb from "./worksections/web/WorksWeb";
import WorksAudio from "./worksections/audio/WorksAudio";
import WorksVideo from "./worksections/video/WorksVideo";
import classNames from "classnames";
import "../works/Works.css";

function Works() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState("1");

  // useEffect(() => {
  //   if (location.state?.section) {
  //     setValue(location.state.section); // Restore previous tab
  //   }
  // }, [location.state]);
  // useEffect(() => {
  //   if (location.state?.section) {
  //     setValue(location.state.section); // Set the value from state
  //   }
  // }, [location]);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  useEffect(() => {
    if (location.state?.section) {
      const sectionToValueMap = {
        art: "1",
        visualdesign: "2",
        web: "3",
        audio: "4",
        video: "5",
      };
      const tabValue = sectionToValueMap[location.state.section] || "1";
      setValue(tabValue);
    }
  }, [location.state]);
  const handleChange = (event, newValue) => {
    const valueToSectionMap = {
      1: "art",
      2: "visualdesign",
      3: "web",
      4: "audio",
      5: "video",
    };

    setValue(newValue);
    navigate("/works", { state: { section: valueToSectionMap[newValue] } });
  };

  const backgroundGradient = () => {
    switch (value) {
      case "1":
        return "linear-gradient(150deg, #FFFFFF, #84FF88)";
      case "2":
        return "linear-gradient(150deg, #FFFFFF, #0041A2)";
      case "3":
        return "linear-gradient(150deg, #FFFFFF, #FCD319)";
      case "4":
        return "linear-gradient(150deg, #FFFFFF, #CEC5EE)";
      case "5":
        return "linear-gradient(150deg, #FFFFFF, #7B04BF)";
      default:
        return "linear-gradient(150deg, #FFFFFF, #CCCCCC)";
    }
  };

  const headerColor = () => {
    switch (value) {
      // case "2": // Visual Design
      // case "5": // Video
      //   return "#FFFFFF";
      default:
        return "#ED1D65";
    }
  };

  // const selectedColor = () => {
  //   switch (value) {
  //     case "1": return "#84FF88"; // Art
  //     case "2": return "#0041A2"; // Visual Design
  //     case "3": return "#FCD319"; // Web
  //     case "4": return "#CEC5EE"; // Audio
  //     case "5": return "#7B04BF"; // Video
  //     default: return "#F78D35";
  //   }
  // };

  // const headerColor = () => {
  //   switch (value) {
  //     // case "2":
  //     //   return "#FFFFFF";
  //     // case "5":
  //     //   return "#FFFFFF";
  //     default:
  //       return "#ED1D65";
  //   }
  // };

  const selectedColor = () => {
    switch (value) {
      case "2":
        return "#F15331";
      case "5":
        return "#FFFFFF";
      default:
        return "#F78D35";
    }
  };

  return (
    <Box
      className={classNames(classes.containerBox)}
      sx={{ background: backgroundGradient() }}
    >
      <Box className={classes.topHeader}>
        <Typography
          component="h1"
          sx={{
            padding: "1rem",
            paddingRight: "4rem",
            color: headerColor(),
          }}
        >
          Works
        </Typography>
        <Divider
          className={classes.topHeaderDivider}
          sx={{
            borderColor: headerColor(),
            boxShadow: `0 0 5px ${headerColor()}`,
          }}
        />
      </Box>
      <Box className={classes.workWrap}>
        <TabContext
          value={value}
          sx={{
            color: headerColor(),
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              borderColor: headerColor(),
              height: "auto",
              width: "-webkit-fill-available",
            }}
            // className={classes.tabDivider}
          >
            {/* <Tabs
              onChange={handleChange}
              aria-label="lab API tabs example"
              className={classes.tabWrap}
              indicatorColor="primary"
              // indicatorColor="blue"
              textColor="#ED1D65"
              variant="fullWidth"
              TabIndicatorProps={{
                style: { backgroundColor: selectedColor() }, // Ensuring the indicator matches the selected tab color
              }}
            > */}
<Tabs
  onChange={handleChange}
  textColor="inherit"
  indicatorColor="primary"
  variant="fullWidth"
  TabIndicatorProps={{
    style: { 
      backgroundColor: selectedColor(),
      height: "4px"
    }
  }}
>
              {/* <Tab
                label="Art"
                value="1"
                className={classes.tabTab}
                // sx={{
                //   color: headerColor(),
                //   "&.Mui-selected": {
                //     color: selectedColor(),
                //   },
                // }}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(), // Highlight the selected tab's text color
                    fontWeight: "bold", // Make it bold to stand out
                  },
                }}
              /> */}
              <Tab
                label="Art"
                value="1"
                className={classes.tabTab}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(), // Will be #F15331
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: selectedColor(),
                    opacity: 0.8,
                  },
                }}
              />
              <Divider
                className={classes.tabDivider}
                orientation="vertical"
                sx={{
                  borderColor: headerColor(),
                }}
              />
              <Tab
                label="Visual Design"
                value="2"
                className={classes.tabTab}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(),
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: selectedColor(),
                    opacity: 0.8,
                  },
                }}
              />
              <Divider
                className={classes.tabDivider}
                orientation="vertical"
                sx={{
                  borderColor: headerColor(),
                }}
              />
              <Tab
                label="Web"
                value="3"
                className={classes.tabTab}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(),
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: selectedColor(),
                    opacity: 0.8,
                  },
                }}
              />
              <Divider
                className={classes.tabDivider}
                orientation="vertical"
                sx={{
                  borderColor: headerColor(),
                }}
              />
              <Tab
                label="Audio"
                value="4"
                className={classes.tabTab}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(),
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: selectedColor(),
                    opacity: 0.8,
                  },
                }}
              />
              <Divider
                className={classes.tabDivider}
                orientation="vertical"
                sx={{
                  borderColor: headerColor(),
                }}
              />
              <Tab
                label="Video"
                value="5"
                className={classes.tabTab}
                sx={{
                  color: headerColor(),
                  "&.Mui-selected": {
                    color: selectedColor(),
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: selectedColor(),
                    opacity: 0.8,
                  },
                }}
              />
            </Tabs>
          </Box>
          {/* <TabPanel value="1" id="art">
            <WorksArt />
          </TabPanel>
          <TabPanel value="2" id="visualdesign">
            <WorksVisualDesign />
          </TabPanel>
          <TabPanel value="3" id="web">
            <WorksWeb />
          </TabPanel>
          <TabPanel value="4" id="audio">
            <WorksAudio />
          </TabPanel>
          <TabPanel value="5" id="video">
            <WorksVideo />
          </TabPanel> */}

          <TabPanel value="1">
            <WorksArt />
          </TabPanel>
          <TabPanel value="2">
            <WorksVisualDesign />
          </TabPanel>
          <TabPanel value="3">
            <WorksWeb />
          </TabPanel>
          <TabPanel value="4">
            <WorksAudio />
          </TabPanel>
          <TabPanel value="5">
            <WorksVideo />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default Works;
