import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
const useStyles = makeStyles((theme) => ({
  containerBox: {
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(134deg, #F2778C, #ED1D65) !important",
    gap: "1rem",
    paddingBottom: "5rem",
  },
    topHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        "@media (max-width: 600px)": {
            alignItems: 'center',
            "& .MuiTypography-root": {
                padding: "5px",
                },
        },
    },
  topHeaderDivider: {
    width: "30vw !important",
    boxShadow: "0 0 5px white",
    borderColor: "white !important",
    borderWidth: "1px !important",
  },
  commissionInfoWrap: {
    textTransform: "math-auto !important",
    padding: '10vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '3vw',
  },
  currentStatusWrap: {
    display: 'flex',
    justifyContent: 'center',
    gap: '50%',
  },
  currentStatus: {
    display: 'flex',
    gap: '1rem',
  },
  currentQueue: {
    display: 'flex',
    gap: '1rem',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3vw',
  },
  infoSubBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1vw',
  },
  infoChartBox: {},
  contactFormWrap: {
    width: "75vw",
    display: "flex",
    padding: "3rem",
    gap: "2rem",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    "@media (max-width: 600px)": {
      padding: "1rem",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contactBox1: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignSelf: 'start',
    "@media (max-width: 1024px)": {
      gap: "1vw",
    },
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",

    },

  },
  contactBox2: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    height: "-webkit-fill-available",
    gap: "1rem",
    marginTop: "2rem",
  },
  contactForm: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gap: "2vw",
    alignItems: "center",
    "@media (max-width: 600px)": {
      padding: "1rem",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      "& .MuiTypography-root": {
        textAlign: "center",
        },
    },
  },
  contactSubmit: {
    width: "100%",
    borderRadius: "0 !important",
    padding: "0.5rem !important",
    boxShadow: "0 0 0 !important",
    backgroundColor: "#ed1d65 !important",
    fontFamily: "Play, sans-serif !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: "#f78d35 !important",
    },
  },
  contactTextField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff !important",
      fontFamily: "Play, sans-serif !important",
      height: "100%", // Default full width
      "@media (max-width: 1024px)": {
        height: "5vh",
        "&:last-of-type": {
          height: "auto",
        },
      },
      "& fieldset": {
        borderColor: "#ed1d65 !important",
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "#d81b60 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ad1457 !important",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#ed1d65 !important",
      fontFamily: "Play, sans-serif !important",
      fontSize: "24px !important",
      "@media (max-width: 1024px)": {
        fontSize: "12px !important",
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#d81b60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#ed1d65 !important",
    },
  },
  sectionDivider: {
    height: "30vw !important",
    boxShadow: "0 0 5px white",
    borderColor: "#ED1D65 !important",
    borderWidth: "2px !important",
    "@media (max-width: 600px)": {
      width: '100% !important',
      height: "0 !important",

        },
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    fontSize: '32px !important',
    fontWeight: 'bold !important',
    "@media (max-width: 1024px)": {
      fontSize: "24px !important",
    },

  },
  mySkillsHeader: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    marginBottom: '1rem',
},
}));

export default useStyles;
