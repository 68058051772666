import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useStyles from "./WorksItemView.styles";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";
import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import '../works/WorkItemView.css';

function WorkItemView() {
  const { id, title } = useParams();

  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const [itemData, setItemData] = useState(null);
  const [error, setError] = useState(null);
  const popperRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const section = location.state?.section || '1';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const selectedAudio = [
  //   {
  //     name: `${parse(itemData.title)}`,
  //     // singer: 'Luis Fonsi',
  //     cover: `${itemData.filemame_cover}`,
  //     musicSrc: `${itemData.filename}`,
  //     // support async fetch music src. eg.
  //     // musicSrc: async () => {
  //     //   return await fetch('/api')
  //     // },
  //   },
  // ];

  console.log(
    `Fetching: https://ericathegreat.com/portfolio/${section}-item.php?id=${id}`
  );

  const [openPreview, setOpenPreview] = React.useState(false);
  const [anchorImagePreview, setAnchorImagePreview] = React.useState(null);

  const handleOpenPreview = (event) => {
    console.log("Clicked Element:", event.currentTarget);
    setAnchorImagePreview(event.currentTarget);
    setOpenPreview((previousOpen) => !previousOpen);
  };

  const handleClickOutside = (event) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target) &&
      !anchorImagePreview.contains(event.target)
    ) {
      setOpenPreview(false);
    }
  };

  useEffect(() => {
    if (openPreview) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPreview]);

  const conditionOpenPreview = openPreview && Boolean(anchorImagePreview);
  const imagepreviewid = conditionOpenPreview ? "transition-popper" : undefined;

  // useEffect(() => {
  //   if (!section) return;
  //   fetch(`https://ericathegreat.com/portfolio/${section}-item.php?id=${id}`)
  //     .then((response) => {
  //       if (!response.ok)
  //         throw new Error(`Failed to fetch ${section} item data.`);
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.error) throw new Error(data.error);
  //       setItemData(data);
  //     })
  //     .catch((err) => setError(err.message));
  // }, [id, section]);

  useEffect(() => {
    if (!section) return;
    fetch(`https://ericathegreat.com/portfolio/${section}-item.php?id=${id}`)
      .then((response) => {
        if (!response.ok)
          throw new Error("Failed to fetch portfolio item data.");
        return response.json();
      })
      .then((data) => {
        console.log("Fetched Data:", data); // Log fetched data
        if (data.error) throw new Error(data.error);
        setItemData(data);
      })
      .catch((err) => setError(err.message));
  }, [id, section]);

  if (error) return <Box>Error: {error}</Box>;
  if (!itemData) return <Box>Loading...</Box>;

  const backgroundGradient = () => {
    switch (section) {
      case "art":
        return "linear-gradient(150deg, #FFFFFF, #84FF88)";
      case "visualdesign":
        return "linear-gradient(150deg, #FFFFFF, #0041A2)";
      case "web":
        return "linear-gradient(150deg, #FFFFFF, #FCD319)";
      case "audio":
        return "linear-gradient(150deg, #FFFFFF, #CEC5EE)";
      case "video":
        return "linear-gradient(150deg, #FFFFFF, #7B04BF)";
      default:
        return "linear-gradient(150deg, #FFFFFF, #CCCCCC)";
    }
  };

  const headerColor = () => {
    switch (value) {
      case "2":
        return "#FFFFFF";
      default:
        return "#ED1D65";
    }
  };

  const selectedColor = () => {
    switch (value) {
      case "2":
        return "#F15331";
      case "5":
        return "#F15331";
      default:
        return "#F78D35";
    }
  };

  console.log(itemData.gallery_true);
  console.log("Navigating to:", `/portfolio/${section}/${id}`, {
    state: { section: section },
  });
  if (error) {
    return (
      <Box>
        <Typography>Error: {error}</Typography>
        <Typography>
          Section: {section}, ID: {id}
        </Typography>
      </Box>
    );
  }

  const handleBack = () => {
    navigate("/works", { state: { section } }); // Go back and remember the section
  };

  return (
    <Box
      className={classes.containerBox}
      sx={{ background: backgroundGradient(), overflow: "visible" }}
    >
      <Box className={classes.topHeader}>
        <Typography
          component="h1"
          sx={{
            padding: "1rem",
            paddingRight: "4rem",
            color: headerColor(),
          }}
        >
          Works
        </Typography>
        <Divider
          className={classes.topHeaderDivider}
          sx={{
            borderColor: headerColor(),
            boxShadow: `0 0 5px ${headerColor()}`,
          }}
        />
      </Box>
      <Button
        variant="text"
        onClick={handleBack}
        sx={{ 
          margin: "1rem", 
          backgroundColor: "#ED1D65", 
          color: "#FFF",
          fontFamily: '"Play", sans-serif !important'
         }}
        className={classes.backButton}
      >
        Back to {section.charAt(0).toUpperCase() + section.slice(1)}
      </Button>
      <Box className={classes.itemWrap}>
        <Box className={classes.itemOverlay} />
        <Box className={classes.itemImageWrap}>
          <img
            // src={`https://ericathegreat.com/portfolio/work/${section}/previews/${itemData.preview_image}-header-preview.jpg`}
            src={`https://ericathegreat.com/portfolio/work/${
              // section || "web"
              section || "web"
            }/previews/${itemData.preview_image}-header-preview.jpg`}
            alt={parse(itemData.title)}
            class={`${classes.itemViewHeadImage}`}
          />
        </Box>
        {/* <Box className={classes.itemTitleWrap} width={"50vw"} height={"12vh"}> */}
        <Box className={classes.itemTitleWrap} width={"60vw"} height={"auto"}>
          <Typography component="h1" className={classes.itemViewTitle}>
            {parse(itemData.title)}
          </Typography>
        </Box>
        <Box
          className={classes.itemTitleWrapBackDrop}
          width={"58vw"}
          height={"6vh"}
        />
      </Box>
      <Box className={classes.infoSection}>
        <Box className={classes.aboutItemWrap}>
          <Box className={classes.aboutItemWrapSideLeft}>
            <Box className={classes.mySkillsHeader}>
              <Typography
                component="h2"
                sx={{
                  padding: "1rem",
                  fontWeight: "bold",
                  color: "#ED1D65",
                }}
              >
                What is this?
              </Typography>
              <Divider className={classes.sectionDivider} />
            </Box>

            <Box className={`item-desc ${classes.aboutItemDescription}`}>
              {/* {itemData.description} */}
              <Typography
                component="body"
                dangerouslySetInnerHTML={{ __html: itemData.description }}
              />
            </Box>
            <Box className={classes.aboutItemCircleWrap}>
              <Box className={classes.aboutItemCircle}>
                <Box className={classes.aboutItemCircleInner}>
                  <Typography
                    component="h3"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Date Started
                  </Typography>
                  <Divider className={classes.aboutItemDivider} />
                  <Typography component="h3">
                    {new Date(itemData.date_started).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                    })}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.aboutItemCircle}>
                <Box className={classes.aboutItemCircleInner}>
                  <Typography
                    component="h3"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    My Role(s)
                  </Typography>
                  <Divider className={classes.aboutItemDivider} />
                  <Typography component="h3">
                    {itemData.roles}
                    {itemData.rolestwo !== "none" && `, ${itemData.rolestwo}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.aboutItemWrapSideRight}>
            <ButtonBase
              // aria-describedby={imagepreviewid}
              aria-describedby={openPreview ? "preview-popper" : undefined}
              type="button"
              onClick={
                itemData.link_only
                  ? () => window.open(itemData.link_only, "_blank") // Open the link in a new tab
                  : handleOpenPreview
              }
              className={classes.aboutItemPreviewButton}
            >
              <img
                src={
                  section === "audio" || "video"
                    ? `https://ericathegreat.com/portfolio/work/${section}/opt/${itemData.filename_opt}`
                    : `https://ericathegreat.com/portfolio/work/${section}/${itemData.filename}`
                }
                alt={parse(itemData.title)}
                className={`${classes.itemImage}`}
              />

              <Box className={classes.aboutItemPreviewImageWrap}>
                {/* <Box  
                            className={classes.aboutItemPreviewImageOverlay}
              /> */}
                <Typography
                  component="span"
                  variant="h3"
                  color="inherit"
                  className={classes.aboutItemPreviewImageText}
                  // sx={(theme) => ({
                  //   position: "relative",
                  //   p: 4,
                  //   pt: 2,
                  //   pb: `calc(${theme.spacing(1)} + 6px)`,
                  // })}
                >
                  Click to{" "}
                  {section === "audio"
                    ? "listen to the audio"
                    : section === "video"
                    ? "watch video"
                    : "see the full image"}
                </Typography>
              </Box>
            </ButtonBase>

            {/* <Popper
              id={imagepreviewid}
              open={openPreview}
              anchorEl={anchorImagePreview}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
                    The content of the Popper.
                  </Box>
                </Fade>
              )}
            </Popper> */}
            {section !== "audio" && section !== "video" && section !== "visualdesign" && section !== "art" && (
              <Button
                className={classes.aboutItemButton}
                variant="contained"
                href={itemData.link}
              >
                Check it out
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.listsWrap}>
          <Box>
            <Box className={classes.listBoxWrap}>
              <Typography component="h2" className={classes.listBoxHeader}>
                Technologies Used
              </Typography>
              <Divider className={classes.listBoxDivider} />
              <Typography component="body" className={classes.listBoxBody}>
                <ul>
                  {/* {itemData.technologies &&
            itemData.technologies.split(",").map((tech, index) => (
              // <li key={index}>
                {tech.trim()}
                // </li>
            ))} */}
                  <Typography
                    component="div"
                    className={classes.listBoxBody}
                    dangerouslySetInnerHTML={{ __html: itemData.technologies }}
                  />
                </ul>
              </Typography>
            </Box>
            <Box className={classes.listBoxWrapBackDrop} />
          </Box>
          <Box>
            <Box className={classes.listBoxWrap}>
              <Typography component="h2" className={classes.listBoxHeader}>
                Features
              </Typography>
              <Divider className={classes.listBoxDivider} />
              <Typography component="body" className={classes.listBoxBody}>
                <ul>
                  <Typography
                    component="div"
                    className={classes.listBoxBody}
                    dangerouslySetInnerHTML={{ __html: itemData.features }}
                  />
                </ul>
              </Typography>
            </Box>
            <Box className={classes.listBoxWrapBackDrop} />
          </Box>
          <Box>
            <Box className={classes.listBoxWrap}>
              <Typography component="h2" className={classes.listBoxHeader}>
                Challenges & Highlights
              </Typography>
              <Divider className={classes.listBoxDivider} />
              <Typography component="body" className={classes.listBoxBody}>
                <ul>
                  <Typography
                    component="div"
                    className={classes.listBoxBody}
                    dangerouslySetInnerHTML={{ __html: itemData.challenges }}
                  />
                </ul>
              </Typography>
            </Box>
            <Box className={classes.listBoxWrapBackDrop} />
          </Box>
        </Box>
      </Box>
      {itemData.gallery_true === "true" && (
        <Box className={classes.galleryWrap}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Gallery
            </Typography>
            <Divider
              className={classes.sectionDivider}
              sx={{
                boxShadow: "0 0 5px #FFFFFF",
                borderColor: "#FFFFFF !important",
              }}
            />
          </Box>
          <Box className={classes.galleryInnerWrap}>
            <ImageList
              className={classes.galleryImageListWrap}
              cols={3}
              rowHeight={500}
            >
              {Array.from({ length: 6 }).map((_, index) => {
                const galleryKey = `gallery${index + 1}`;
                const galleryImage = itemData[galleryKey];

                return (
                  galleryImage && (
                    <ImageListItem key={galleryKey}>
                      <img
                        src={`https://ericathegreat.com/portfolio/gallery/${section}/${parse(
                          itemData.title
                        )}/gallery_photo_${galleryImage}.png?w=500&fit=crop&auto=format`}
                        alt={parse(itemData.title)}
                        loading="lazy"
                      />
                    </ImageListItem>
                  )
                );
              })}
            </ImageList>
          </Box>
        </Box>
      )}

      <Popper
        id={imagepreviewid}
        open={openPreview}
        anchorEl={anchorImagePreview}
        transition
        className={classes.previewPopper}
        ref={popperRef}
        placement="top"
        modifiers={[
          { name: "preventOverflow", options: { boundary: "viewport" } },
          { name: "offset", options: { offset: [0, 10] } },
          { name: "computeStyles", options: { adaptive: false } },
        ]}
        strategy="fixed"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.previewBackground}>
              <IconButton
                className={classes.closeButton}
                onClick={() => setOpenPreview(false)}
              >
                <CancelIcon
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              </IconButton>
              <Box className={classes.previewBox}>
                <Box className={classes.previewBorderImage}>
                  {/* <img
                    src={`https://ericathegreat.com/portfolio/work/${section}/${itemData.filename}`}
                    alt={parse(itemData.title)}
                    className={`${classes.itemImageFullSize}`}
                  /> */}
                  {section === "audio" ? (
                    // <ReactAudioPlayer
                    //   src={`https://ericathegreat.com/portfolio/work/audio/${itemData.filename}`}
                    //   autoPlay
                    //   controls
                    //   className={classes.workItemAudioPlayer}
                    // />
                    <Box className={classes.audioPlayerWrap}>
                      <Box className={classes.audioPlayerSide1}>
                        <img
                          src={`https://ericathegreat.com/portfolio/work/audio/${itemData.filenamecover}`}
                          alt={parse(itemData.title)}
                          className={`${classes.audioPlayerImage}`}
                        />
                      </Box>
                      <Box className={classes.audioPlayerSide2}>
                        <Typography
                          component="h3"
                          className={classes.audioPlayerTitle}
                        >
                          {parse(itemData.title)}
                        </Typography>
                        <Divider className={classes.audioPlayerDivider} />
                        <AudioPlayer
                          autoPlay
                          src={`https://ericathegreat.com/portfolio/work/audio/${itemData.filename}`}
                          onPlay={(e) => console.log("onPlay")}
                          className={`item-audio-player ${classes.workItemAudioPlayer}`}
                        />
                      </Box>
                    </Box>
                  ) : section === "video" ? (
                    <ReactPlayer
                      className="react-player"
                      url={`https://ericathegreat.com/portfolio/work/video/${itemData.filename}`}
                      width="100%"
                      height="100%"
                      controls
                      autoPlay
                    />
                  ) : (
                    <img
                      src={`https://ericathegreat.com/portfolio/work/${section}/${itemData.filename}`}
                      alt={parse(itemData.title)}
                      className={`${classes.itemImageFullSize}`}
                    />
                  )}

                  {/* {section === "audio" ? (
                    <AudioPlayer
                      audioLists={selectedAudio}
                      theme="dark"
                      autoPlay={true}
                      onAudioPlay={(audio) =>
                        console.log("Audio is playing:", audio)
                      }
                    />
                  ) : (
                    <img
                      src={`https://ericathegreat.com/portfolio/work/${section}/${itemData.filename}`}
                      alt={parse(itemData.title)}
                      className={`${classes.itemImageFullSize}`}
                    />
                  )} */}
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default WorkItemView;
