import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerBox: {
    position: "relative", // To allow absolute positioning of the gradient
    width: "100%",
    height: "100vh", // Full viewport height
    display: "flex",
    justifyContent: "space-between", // Space between text and image
    alignItems: "center",
    overflow: "hidden", // Prevents overflow from absolute elements
    padding: "0 !important",
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column-reverse",
    },
  },
  gradientOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "-webkit-fill-available", // Covers 60% of the container
    height: "100%",
    background: "linear-gradient(90deg, #F15331, rgb(241 83 49 / 0%))", // Gradient from orange to yellow
    zIndex: 3, // Places it above the container background but below content
    pointerEvents: "none", // Allows clicking through the gradient
    "@media (max-width: 600px)": {
      background: "linear-gradient(0deg, #F15331, rgb(241 83 49 / 0%))", // Gradient from orange to yellow
    },
  },
  textBox: {
    zIndex: 4, // Ensures text is above the gradient
    maxWidth: "50%",
    padding: "2rem",
    color: "white",
    "@media (max-width: 600px)": {
      position: "absolute",
      bottom: 0,
      marginBottom: "5rem",
    },
  },
  heading: {
    fontFamily: "Play, sans-serif !important",
    fontSize: "4vw !important",
    fontWeight: "bold",
  },
  subHeading: {
    fontFamily: "Play, sans-serif !important",
    fontSize: "12vw !important",
    fontWeight: "bolder",
    textShadow: "0px 0px 4px #FFFFFF", // Adds shadow to text
  },
  imageBox: {
    zIndex: 2, // Ensures the image is above the gradient
    // width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",
    },
  },
  galleryPic: {
    width: "100%",
    height: "auto",
    clipPath: "polygon(0 0, 100% 0, 90% 100%, 10% 100%)", // Adds a custom shape
  },
  fadeOverlay: {
    height: "100vh",
    width: "20vw",
    background:
      "linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0%))",
    position: "absolute",
    zIndex: 1,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
});

export default useStyles;
