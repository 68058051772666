import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import useStyles from "./WorksVisualDesign.styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import parse from "html-react-parser";

function WorksVisualDesign() {
  const classes = useStyles();
    const navigate = useNavigate();

  // const [value, setValue] = React.useState('1');

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // }

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   // Fetch data from the PHP backend
  //   fetch("https://ericathegreat.com/portfolio/work/visualdesign")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data from the server");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => setData(data))
  //     .catch((err) => setError(err.message));
  // }, []);


  // useEffect(() => {
  //   fetch("https://ericathegreat.com/portfolio/visual-designs-get.php")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data from the server");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => setData(data))
  //     .catch((err) => setError(err.message));
  // }, []);
  // useEffect(() => {
  //   fetch("https://ericathegreat.com/portfolio/visual-designs-get.php")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch data from the server");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Visual Design Data:", data);
  //       setData(data);
  //     })
  //     .catch((err) => setError(err.message));
  // }, []);
    useEffect(() => {
      fetch("https://ericathegreat.com/portfolio/visual-designs-get.php")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data from the server");
          }
          return response.json();
        })
        .then((data) => setData(data))
        .catch((err) => setError(err.message));
    }, []);
  

  if (error) {
    return <Box>Error: {error}</Box>;
  }

  const handleCardClick = (id) => {
    // Navigate to a dynamic route
    // navigate(`/portfolio/${id}`);
    navigate(`/portfolio/visualdesign/${id}`, { state: { section: "visualdesign" } });
  };

  return (
    <Box className={classes.workWrapper}>
          {data.map((item) => (
            // <article key={item.id}>
            //   <h3>
            //     <a href={`https://ericathegreat.com/portfolio/visual-design-view.php?id=${item.id}`}>
            //       {item.title}
            //     </a>
            //   </h3>
            //   <a href={`https://ericathegreat.com/portfolio/visual-design-view.php?id=${item.id}`}>
            //     <img
            //       src={item.image_url}
            //       alt={item.title}
            //       className="gallery-image"
            //     />
            //   </a>
            // </article>
            <Card key={item.id} sx={{ minWidth: 500, maxHeight: '100%' }}>
            <CardActionArea onClick={() => handleCardClick(item.id)}>
            <div className="image-container">
              <CardMedia
                component="img"
                height="300"
                image={`https://ericathegreat.com/portfolio/work/visualdesign/opt/${item.filename_opt}`}
                alt={item.title}
                className={classes.cardImage}
              />
              <Box className={classes.hoverOverlay}>
                <Typography variant="h4" className={classes.hoverText}>
                  {parse(item.title)}
                </Typography>
              </Box>
            </div>
            </CardActionArea>
          </Card>
          ))}


    </Box>
  );
}

export default WorksVisualDesign;
