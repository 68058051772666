import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
  const useStyles = makeStyles((theme) => ({
containerBox: {
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(134deg, #F2778C, #ED1D65) !important",
    gap: "1rem",
    paddingBottom: "5rem",
  },
    topHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        "@media (max-width: 600px)": {
            alignItems: 'center',
            "& .MuiTypography-root": {
                padding: "5px",
                },
        },
    },
  topHeaderDivider: {
    width: "30vw !important",
    boxShadow: "0 0 5px white",
    borderColor: "white !important",
    borderWidth: "1px !important",
  },
  contactInfoWrap: {
    textTransform: 'math-auto !important',
  },
  mainSection: {
    display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
    justifyContent: "center",
  },
  contactFormWrap: {
    width: "75vw",
    display: "flex",
    padding: "3rem",
    gap: "2rem",
    flexDirection: "column",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
        "@media (max-width: 600px)": {
          padding: "1rem",
        },
  },
  contactBox1: {
    display: "flex",
    flexDirection: "column",
    gap: '2rem',
    "@media (max-width: 1024px)": {
      gap: "1vw",
    },
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",

        },
  },
  contactBox2: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '-webkit-fill-available',
    gap: '1rem',
    marginTop: '2rem',
    "@media (max-width: 600px)": {
      marginTop: '1rem',
      alignItems: 'center',

        },
  },
  contactForm: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gap: "2vw",
    alignItems: 'center',
    "@media (max-width: 600px)": {
      display: 'flex',
      flexDirection: 'column',
        },
  },
  contactSubmit: {
    width: "100%",
    borderRadius: "0 !important",
    padding: "0.5rem !important",
    boxShadow: "0 0 0 !important",
    backgroundColor: "#ed1d65 !important",
    fontFamily: "Play, sans-serif !important",
    fontWeight: "bold !important",
    fontSize: '24px !important',
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: "#f78d35 !important",
    },
  },
  contactTextField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#ffffff !important", 
      fontFamily: "Play, sans-serif !important",
      height: "100%", // Default full width
      "@media (max-width: 1024px)": {
        height: "5vh",
        '&:last-of-type': {
          height: "auto",

      },
      },
      "& fieldset": {
        borderColor: "#ed1d65 !important",
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "#d81b60 !important", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ad1457 !important", 
      },
    },
    "& .MuiInputLabel-root": {
      color: "#ed1d65 !important", 
      fontFamily: "Play, sans-serif !important",
      fontSize: '24px !important',
      "@media (max-width: 1024px)": {
        fontSize: '12px !important',
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#d81b60 !important", 
    },
    "& .MuiFormHelperText-root": {
      color: "#ed1d65 !important", 
    },

  },
  sectionDivider: {
    height: "30vw !important",
    boxShadow: "0 0 5px white",
    borderColor: "#ED1D65 !important",
    borderWidth: "2px !important",
    "@media (max-width: 600px)": {
      width: '100% !important',
      height: "0 !important",

        },
  },
  socialSection: {},
  socialMediaWrap: {
    display: "flex",
    gap: "1vw",
    justifyContent: "center",
    color: "#ed1d65 !important", 
  },
  socialMediaLink: {
    // background: 'linear-gradient(180deg, #F78D35, #FCD319) !important',
    // padding: "1vw !important",
    // borderRadius: '16vw !important',
  },
  socialMediaLinkImg: {
    width: "3vw",
  },
}));

export default useStyles;
