import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import useStyles from "./Home.styles";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Home.css";

import tempPic from "../../../uploads/images/gallery/temp.png";

function Home() {
  const classes = useStyles();
  const images = [
    "https://cdn.characterhub.com/users/44935247-1504-4d61-b4b4-247cf0c1bacb/25cc16af-3033-4b25-b7fd-281f15442008_l",
    "https://cdn.characterhub.com/users/44935247-1504-4d61-b4b4-247cf0c1bacb/0c87826d-e7aa-4ff1-bc32-9abb38d5fdcc_l",
    "https://i.postimg.cc/CxJxPdNx/Fairy-Fiona-for-shirt-sake.png",
  ];

  return (
    <Box className={classes.containerBox}>
      {/* Gradient Overlay */}
      <Box className={classes.gradientOverlay}></Box>

      {/* Text Section */}
      <Box className={classes.textBox}>
        <Typography component="h1" variant="h3" className={classes.heading}>
          Welcome to my world of
        </Typography>
        <Typography component="h1" variant="h1" className={classes.subHeading}>
          WORKS
        </Typography>
      </Box>

      {/* Image Section */}
      <Box className={classes.imageBox}>
        {/* <img
          src={tempPic}
          alt="temporary gallery"
          className={classes.galleryPic}
        /> */}
        <div>
        <div className={classes.fadeOverlay} />
        <Slide className="slideshow-wrap">
          <div className="each-slide-effect">
            <img
              src={images[0]}
              className="slide-image"
              alt="gallery"

            />
          </div>
          <div className="each-slide-effect">
            <img
              src={images[1]}
              className="slide-image"
              alt="gallery"

            />
          </div>
          <div className="each-slide-effect">
            <img
              src={images[2]}
              className="slide-image"
              alt="gallery"
            />
          </div>
        </Slide>
        </div>
      </Box>
    </Box>
  );
}

export default Home;
