import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerBox: {
    display: "flex",
    zIndex: 2,
  },
  footerLink: {
    color: '#E91E63',
    "&:hover": {
      backgroundColor: 'rgb(233 30 99 / 25%)',
      transition: '0.3s',
        },
    "@media (max-width: 600px)": {
      padding: 0,
    },
  },
  footerSocial: {
    display: "flex",
    gap: "1vw",
    justifyContent: "center",
    alignItems: "center",
  },
  footerCopyright: {
    display: "flex",
    fontSize: "18px",
    "@media (max-width: 1680px)": {
      fontSize: "12px",
    },
    gridColumn: "1 / 3",
    justifyContent: "center",
    alignItems: "center",
  },
  footerWrap: {
    backgroundColor: "#DF412C",
    color: "white",
    width: "100%", // -webkit-fill-available equivalent
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    padding: "1rem",
    "@media (max-width: 600px)": {
      padding: '5px !important',
    },
  },
  footerLinkWrap: {
    display: "flex",
    gap: "1vw",
    justifyContent: "center",
    alignItems: 'center',
    "@media (max-width: 600px)": {
      gap: "0.2vw",
    },
  },
  footerDivider: {
    alignSelf: "center !important",
    height: "2vw !important",
    borderColor: "white !important",
    boxShadow: "0 0 5px white",
  },
  socialMediaWrap: {
    display: "flex",
    gap: "1vw",
    justifyContent: "center",
  },
  socialMediaLink: {
    background: 'linear-gradient(180deg, #F78D35, #FCD319) !important',
    padding: '1vw !important',
    borderRadius: '16vw !important',
  },
  socialMediaLinkImg: {
        width: '1.5vw',
  },
});

export default useStyles;
