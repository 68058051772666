import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./components/header/Header";
import Home from "./pages/main/home/Home";
import About from "./pages/main/about/About";
import Footer from "./components/footer/Footer";
import "react-datetime/css/react-datetime.css";
import Works from "./pages/main/works/Works";
import Contact from "./pages/main/contact/Contact";
import WorkItemView from "./pages/main/works/WorkItemView";
import Commissions from "./pages/main/commissions/Commissions";
import { Container, ThemeProvider, Slide } from "@mui/material";
import generalTheme from "./themes/generalTheme";
import useStyles from "./pages/main/Main.styles";

function App() {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
    exit: {
      opacity: 0,
      x: "100vw",
      transition: { duration: 0.5 },
    },
  };

  const handlePageHome = () => {
    navigate("/");
  };

  const handlePageAbout = () => {
    navigate("/about");
  };

  const handlePageWorks = () => {
    navigate("/works");
  };

  const handlePageContact = () => {
    navigate("/contact");
  };

  const handlePageCommission = () => {
    navigate("/commissions");
  };

  return (
    <div className="App">
      <ThemeProvider theme={generalTheme}>
        <Header
          handlePageHome={handlePageHome}
          handlePageAbout={handlePageAbout}
          handlePageWorks={handlePageWorks}
          handlePageContact={handlePageContact}
          handlePageCommission={handlePageCommission}
        />
        {/* <Container className={classes.mainContainerBox}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/works" element={<Works />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/commissions" element={<Commissions />} />
            <Route path="/portfolio/:section/:id" element={<WorkItemView />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Container> */}
        <Container className={`main-container ${classes.mainContainerBox}`}>
          {/* AnimatePresence enables animations during component mounting/unmounting */}
          <AnimatePresence mode="wait" className="animated-box">
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Home />
                  </motion.div>
                }
              />
              <Route
                path="/about"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <About />
                  </motion.div>
                }
              />
              <Route
                path="/works"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Works />
                  </motion.div>
                }
              />
              <Route
                path="/contact"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Contact />
                  </motion.div>
                }
              />
              <Route
                path="/commissions"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Commissions />
                  </motion.div>
                }
              />
              <Route
                path="/portfolio/:section/:id"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <WorkItemView />
                  </motion.div>
                }
              />
              <Route
                path="/"
                element={
                  <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <Home />
                  </motion.div>
                }
              />
              <Route path="*" element={<Home />} />
            </Routes>
          </AnimatePresence>
        </Container>
        <Footer
          handlePageHome={handlePageHome}
          handlePageAbout={handlePageAbout}
          handlePageWorks={handlePageWorks}
          handlePageContact={handlePageContact}
          handlePageCommission={handlePageCommission}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
