import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import mainLogo from "../../uploads/general-assets/logo.png";
// import { useNavigate } from "react-router-dom";
import useStyles from "./Footer.styles";
import "../footer/Footer.css";

import SocialMediaButton1 from "../../uploads/general-assets/social-media-icons/Social_Media_Icon_Email_nobg.svg";
import SocialMediaButton2 from "../../uploads/general-assets/social-media-icons/Social_Media_Icon_LinkedIn_nobg.svg";
import SocialMediaButton3 from "../../uploads/general-assets/social-media-icons/Social_Media_Icon_Instagram_nobg.svg";

function Footer(props) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Box className={classes.containerBox}>
      <Box className={classes.footerWrap}>
        <Box className={classes.footerLinkWrap}>
          <Button className={classes.footerLink} onClick={props.handlePageHome}>
            <Typography component="body" variation="body1">
              Home
            </Typography>
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.footerDivider}
          />
          <Button
            className={classes.footerLink}
            onClick={props.handlePageAbout}
          >
            <Typography component="body" variation="body1">
              About
            </Typography>
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.footerDivider}
          />
          <Button
            className={classes.footerLink}
            onClick={props.handlePageWorks}
          >
            <Typography component="body" variation="body1">
              Works
            </Typography>
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.footerDivider}
          />
          <Button
            className={classes.footerLink}
            onClick={props.handlePageContact}
          >
            <Typography component="body" variation="body1">
              Contact
            </Typography>
          </Button>
        </Box>
        <Box className={classes.footerSocial}>
          <Typography component="body" variation="body1">
            Want to Connect?
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.footerDivider}
          />
          <Box className={`social-media-wrap ${classes.socialMediaWrap}`}>
            <Button
              className={classes.socialMediaLink}
              href="mailto:ericajgarbino@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={SocialMediaButton1}
                alt="Social Media Icon (Email)"
                className={classes.socialMediaLinkImg}
              />
            </Button>
            <Button
              className={classes.socialMediaLink}
              href="https://www.linkedin.com/in/erica-garbino-992ab416b/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={SocialMediaButton2}
                alt="Social Media Icon (LinkedIn)"
                className={classes.socialMediaLinkImg}
              />
            </Button>
            <Button
              className={classes.socialMediaLink}
              href="https://www.instagram.com/theyoungmightysoldier/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={SocialMediaButton3}
                alt="Social Media Icon (Instagram)"
                className={classes.socialMediaLinkImg}
              />
            </Button>
          </Box>
        </Box>
        <Box className={classes.footerCopyright}>
          <Typography
            component="body"
            variation="body2"
            sx={{
              fontSize: "14px",
              "@media (max-width: 1680px)": {
                fontSize: "8px",
              },
            }}
          >
            &#64; Copyright Erica Garbino 2019 - {currentYear}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
