import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import useStyles from "./Commissions.styles";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import emailjs from "emailjs-com";
import { ThemeProvider } from "@mui/styles";
import contactFormTheme from "./CommissionsFormTheme";
import "../commissions/Commissions.css";
import Link from "@mui/material/Link";

// import SocialMediaButton1 from "../../uploads/general-assets/social-media-icons/Social_Media_Icon_Email_nobg.svg";
import SocialMediaButton1 from "../../../uploads/general-assets/social-media-icons/Social_Media_Icon_LinkedIn_nobg_pink.svg";
import SocialMediaButton2 from "../../../uploads/general-assets/social-media-icons/Social_Media_Icon_Instagram_nobg_pink.svg";

function Commissions() {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.message) validationErrors.message = "Message is required";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const templateParams = {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };

      emailjs
        .send(
          "service_aykafxj", // Replace with your EmailJS service ID
          "template_u0lcc15", // Replace with your EmailJS template ID
          templateParams,
          "HEJ7GVVpLERT2gXC3" // Replace with your EmailJS user ID
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setFormData({ name: "", email: "", message: "" });
            alert("Message sent successfully!");
          },
          (error) => {
            console.error("FAILED...", error);
            alert("Message failed to send.");
          }
        );
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const validationErrors = {};
  //   if (!formData.name) validationErrors.name = "Name is required";
  //   if (!formData.email) validationErrors.email = "Email is required";
  //   if (!formData.message) validationErrors.message = "Message is required";

  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length === 0) {
  //     console.log("Form Submitted:", formData);
  //     // Add your submission logic here (e.g., send to API)
  //     setFormData({ name: "", email: "", message: "" });
  //   }
  // };

  return (
    <Box className={classes.containerBox}>
      <Box className={classes.topHeader}>
        <Typography
          component="h1"
          sx={{
            padding: "1rem",
            paddingRight: "4rem",
          }}
        >
          Commissions
        </Typography>
        <Divider className={classes.topHeaderDivider} />
      </Box>
      <Box className={classes.commissionInfoWrap}>
        <Box className={classes.currentStatusWrap}>
          <Box className={classes.currentStatus}>
            <Typography component="body" sx={{}}>
              Current status:
            </Typography>
            <Typography
              component="body"
              sx={{
                fontWeight: "bold",
              }}
            >
              Active
            </Typography>
          </Box>
          <Box className={classes.currentQueue}>
            <Typography component="body" sx={{}}>
              No. of people in queue:
            </Typography>
            <Typography
              component="body"
              sx={{
                fontWeight: "bold",
              }}
            >
              0
            </Typography>
          </Box>
        </Box>
        <Box className={classes.infoSubBox}>
          <Typography component="body" sx={{}}>
            If you would like to request a commission from me, I'll be happy to
            make one for you. In fact, I will be grateful if you did.
          </Typography>
          <Typography component="body" sx={{}}>
            Right now, I am focusing on full body commissions of your requested
            characters, but I can also do other things, such as flags, posters,
            etc. Don't be afraid to ask. Just note this is mostly art focused,
            unless you asked for a layout design regarding the full body
            commission.
          </Typography>
          <Typography component="body" sx={{}}>
            Please read the guidelines carefully. These apply to BOTH full body
            and general commissions. Failure to follow them will result you
            getting blacklisted, and if you make attempts to make alternate
            emails or accounts, I will take legal measures.{" "}
          </Typography>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Terms & Service
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Typography component="h3" className={classes.subHeader}>
              As the Artist:
            </Typography>
            <Typography component="body" sx={{}}>
              I have the right to reject the commission if it goes against my
              rules and conditions or if it makes me uncomfortable.
            </Typography>
            <Typography component="body" sx={{}}>
              There are also times where I may have to reject it if it goes
              beyond my strength, even if your request follows my guidelines.
            </Typography>
            <Typography component="body" sx={{}}>
              I have the right to post the following art anywhere, which
              includes the portfolio I am currently building on. In addition,
              you will be credited as the commissioner unless you prefer not to
              have your name shown.
            </Typography>
            <Typography component="body" sx={{}}>
              For further transparency purposes, I won't post my art in any NSFW
              sites unless there is a SFW section, which is unlikely.
            </Typography>
            <Typography component="body" sx={{}}>
              Prices will change depending on the demands.
            </Typography>
          </Box>
          <Box className={classes.infoSubBox}>
            <Typography component="h3" className={classes.subHeader}>
              You as the buyer:
            </Typography>
            <Typography component="body">
              Can also share this anywhere EXCEPT any NSFW sites or groups
              unless a section contains SFW art.
            </Typography>
            <Typography component="body">
              Use the finished art for personal use. No commercial use, so
              please don't use it to sell them, regardless if it is physical or
              digital. If your request happens to be made for commercial use, we
              can discuss that.
            </Typography>
            <Typography component="body">
              Promote yourself, as long as you give credit and claim your
              character and not the art itself.
            </Typography>
            <Typography component="body">
              May not make any changes to the art without letting me know.
            </Typography>
            <Typography component="body">
              May not remove the signature of my art.
            </Typography>
            <Typography component="body">
              May not claim the art as your own.
            </Typography>
            <Typography component="body">
              May not use the art for AI training or NFTs.
            </Typography>
            <Typography component="body">
              Payments will be handled through PayPal.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Process
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Typography component="body">
              1. Please provide clear references of the full body. If you could
              include the back and sides, that would be appreciated, but I just
              need the front. If you want to have the layout design included,
              also provide references.
            </Typography>
            <Typography component="body">
              2. Once approved by me, I will create a rough sketch. I will be
              doing 2 revisions max.
            </Typography>
            <Typography component="body">
              3. Once you approve the sketch, a 50% deposit will be required to
              secure the commission and proceed to the next stages. I won't
              start working until the deposit is received.
            </Typography>
            <Typography component="body">
              4. Depending on some circumstances, it may take 1-2 weeks to
              finish the piece assuming I am working consistently. However, this
              may take longer depending on how complex the art is. Not only
              that, but queues are a thing too.
            </Typography>
            <Typography component="body">
              5. I will do my best to keep you updated during my work with your
              request, especially if it takes a bit longer. I will let you know
              which phase I am in, whether line, color, shade, or whatever.
            </Typography>
            <Typography component="body">
              6. If you choose to have the layout design as part of your
              request, I will also provide a draft version of it with the sketch
              phase, which will be in black & white.
            </Typography>
            <Typography component="body">
              7. Once the final piece is made, as mentioned earlier, you will be
              provided with several versions in full-resolution.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Refunds
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Typography component="body">
              If you decided to cancel during the sketch phase, and no deposit
              has been made, no refunds will need to apply. However, after this,
              this will be non-refundable.
            </Typography>
            <Typography component="body">
              For the final work phase, should you cancel, you will get refunded
              based on how much progress I have made. For example, if I am
              halfway through this phase, you will be provided with 25% of the
              refund.
            </Typography>
            <Typography component="body">
              If the art happens to be close to finishing, but you decide to
              cancel, no refunds are applicable at this point.
            </Typography>
            <Typography component="body">
              If for some reason I can't complete the work, a full refund will
              be issued.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Dos & Don'ts
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Box className={classes.contactFormWrap}>
              <Box
                className={classes.contactForm}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto 1fr",
                  gridTemplateRows: "auto auto",
                  width: "-webkit-fill-available",
                  "@media (max-width: 600px)": {
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& .MuiTypography-root": {
                      textAlign: "center",
                    },
                  },
                }}
              >
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Dos
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                    }}
                  >
                    <>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        OCs
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Fanart
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Humans or humanoids (Anthros I may want to see your
                        character first before I could make a decision)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        SFW (Suggestive themes and mild nudity, such as some
                        cleavage shown, is fine, as I don't mind drawing sexy
                        characters, though ask me if unsure)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Light blood
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Ships (Hetero ones, sorry)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Simple backgrounds (Patterns are fine too)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Flags
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Posters
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        General art
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Icons
                      </Typography>
                    </>
                  </Box>
                </Box>
                <Divider
                  className={classes.sectionDivider}
                  orientation="vertical"
                />
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Don'ts
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                    }}
                  >
                    <>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Furries (Not good at it yet. The closest thing I can do
                        okay but not great on is a crocodile. If you do request
                        that kind of character, I will have to see it for
                        myself, so it is not guaranteed.)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Mecha
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        NSFW (Especially full-on nudity)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Political art
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Hate art
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Overly detailed backgrounds
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Layouts that are a copy of already made templates (ex.
                        Pokemon cards). I do not want to plagiarize anything.
                      </Typography>
                    </>
                  </Box>
                </Box>
                <Box
                  className={classes.contactBox1}
                  sx={{
                    gridColumnStart: 1,
                    gridColumnEnd: 4,
                    width: "-webkit-fill-available",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Maybe
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                    }}
                  >
                    <>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Overly detailed designed characters (If unsure, ask.
                        This one can also be arranged, but most likely not
                        guaranteed.)
                      </Typography>
                    </>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Prices
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Box className={classes.contactFormWrap}>
              <Box className={classes.contactForm}>
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Full Body
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2vw",
                    }}
                  >
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Base (the full body): $5
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Background included: Additional $5
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Layout design: Additional $5
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Items at the end
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Base (the full body): Two versions (Solid white
                        background and no background)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Background included: Two versions (Chosen background and
                        no background)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Layout design: Three versions (Layout design, w/o layout
                        design and no background)
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Regarding layout design
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        I want to use my graphic design skills to an advantage,
                        so I decided to offer a layout to enhance the piece
                        further. That, and to make my portfolio nicer.
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        I can provide you with a layout like what we see in
                        magazine covers, or perhaps you want to make it look
                        like a trading card or whatever.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Here are some examples of reference (NOT mine):
                      </Typography>
                      <Link
                        href="https://project-imas.wiki/images/4/4d/Haruka_TD_N.png"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#d81b60 !important",
                          fontStyle: "italic",
                          textDecorationColor: "#E91E63",
                          transition: "0.3s",
                          "&:hover": {
                            textDecorationColor: "#FF5722",
                            color: "#FF5722 !important",
                            fontSize: "26px !important",
                            transition: "0.3s",
                          },
                        }}
                      >
                        Example 1
                      </Link>
                      <br />
                      <Link
                        href="https://jp.pinterest.com/pin/37858453102314014/"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#d81b60 !important",
                          fontStyle: "italic",
                          textDecorationColor: "#E91E63",
                          transition: "0.3s",
                          "&:hover": {
                            textDecorationColor: "#FF5722",
                            color: "#FF5722 !important",
                            fontSize: "26px !important",
                            transition: "0.3s",
                          },
                        }}
                      >
                        Example 2
                      </Link>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        It’s a bit experimental, but I am willing to make it
                        look nice and make the piece worth it. However, it is up
                        to you if you’d like! If you choose to do this, we can
                        discuss it further.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  className={classes.sectionDivider}
                  orientation="vertical"
                />
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    General
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2vw",
                    }}
                  >
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Unlike the full body art, the price will depend on what
                        the item is. If this is the route you are going for,
                        please follow the steps carefully.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Factors
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Size - The bigger the size, the more likely it will be
                        expensive.
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Amount of content/details - Same applies to size. The
                        more content and details it has, the more expensive it
                        will be.
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Purpose - As mentioned, if this is used for commercial
                        purposes, please let me know. If this is also the case,
                        the price may be higher.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              If you choose to Commission
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Box className={classes.contactFormWrap}>
              <Box className={classes.contactForm}>
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Full Body
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2vw",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      component="body"
                      sx={{
                        color: "#d81b60",
                      }}
                    >
                      Please read my TOS above
                    </Typography>
                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        When requesting, please provide the following
                        information:
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Character, whether oc or fanart
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Pose (standing, sitting on something, etc.)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Expression (Happy, sad, frustrated, etc.)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Theme (Pop, chic, cool, punk, etc.)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Background (if you choose to)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Layout design (if you choose to)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Additional details
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        If you happen to request your oc and they have a
                        CharacterHub profile, please share the link!
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        For now, you will have to pay through PayPal
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Initial Payment: 50% of the varied price
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider
                  className={classes.sectionDivider}
                  orientation="vertical"
                />
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    General
                  </Typography>
                  <Box
                    className={classes.infoSubBox}
                    sx={{
                      color: "#d81b60",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2vw",
                    }}
                  >
                    <Typography
                      component="body"
                      sx={{
                        color: "#d81b60",
                      }}
                    >
                      Please read my TOS above
                    </Typography>

                    <Box>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        When requesting, please provide the following
                        information:
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        What kind of item or product you want me to make
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        What this product is for
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        The content of the product
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Brand (if it contains the expected colors, fonts, etc.)
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Expected deadline
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        Additional details
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                        }}
                      >
                        For now, you will have to pay through PayPal
                      </Typography>
                      <Typography
                        component="body"
                        sx={{
                          color: "#d81b60",
                          fontWeight: "bold",
                        }}
                      >
                        Initial Payment: 50% of the varied price
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.mySkillsHeader}>
            <Typography
              component="h2"
              sx={{
                padding: "1rem",
                fontWeight: "bold",
              }}
            >
              Submit Request
            </Typography>
            <Divider className={classes.topHeaderDivider} />
          </Box>
          <Box className={classes.infoSubBox}>
            <Box className={classes.contactFormWrap}>
              <Box className={classes.contactForm}>
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Full Body
                  </Typography>
                  <>
                    <Typography
                      component="body"
                      sx={{
                        color: "#d81b60",
                        fontWeight: "bold",
                      }}
                    >
                      Please proceed to CharacterHub below:
                    </Typography>
                    <Link
                      href="https://characterhub.com/marketplace/listings/details/full-body-commissions-gpn4D66M"
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "#d81b60 !important",
                        fontStyle: "italic",
                        textDecorationColor: "#E91E63",
                        transition: "0.3s",
                        "&:hover": {
                          textDecorationColor: "#FF5722",
                          color: "#FF5722 !important",
                          fontSize: "26px !important",
                          transition: "0.3s",
                        },
                      }}
                    >
                      Full Body Commissions
                    </Link>
                    <Typography
                      component="body"
                      sx={{
                        color: "#d81b60",
                      }}
                    >
                      If by any means you don't have a CharacterHub account, you
                      may use the form on the right.
                    </Typography>
                  </>
                </Box>
                <Divider
                  className={classes.sectionDivider}
                  orientation="vertical"
                />
                <Box className={classes.contactBox1}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: "#d81b60",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    General
                  </Typography>
                  <Typography
                    component="body"
                    sx={{
                      color: "#d81b60",
                      fontWeight: "bold",
                    }}
                  >
                    Or if you don't have a CharacterHub account
                  </Typography>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{ marginBottom: 2 }}
                    className={classes.contactTextField}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ marginBottom: 2 }}
                    className={classes.contactTextField}
                  />
                  <TextField
                    fullWidth
                    label="What kind of request you want to make?"
                    name="message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    error={!!errors.message}
                    helperText={errors.message}
                    sx={{ marginBottom: 2 }}
                    className={classes.contactTextField}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    className={classes.contactSubmit}
                    sx={{
                      backgroundColor: "#d81b60",
                      "&:hover": { backgroundColor: "#ad1457" },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Commissions;
