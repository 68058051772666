import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerBox: {
    display: "flex",
    flexDirection: "column",
    // background: "linear-gradient(180deg, #FFFFFF, #ED1D65) !important",
    // gap: '1rem',
    // paddingBottom: '5rem',
    transition: "background 0.5s ease-in-out",
    width: "100%",
  },
  topHeader: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: "2rem",
    "@media (max-width: 600px)": {
      alignItems: "center",
      "& .MuiTypography-root": {
        padding: "5px",
      },
    },
  },
  topHeaderDivider: {
    width: "30vw !important",
    // boxShadow: '0 0 5px #ED1D65',
    // borderColor: '#ED1D65 !important',
    borderWidth: "1px !important",
  },
  sectionDivider: {
    width: "30vw !important",
    boxShadow: "0 0 5px #ED1D65",
    borderColor: "#ED1D65 !important",
    borderWidth: "1px !important",
  },
  mySkillsHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
  },
  itemViewTitle: {
    color: "#ed1d65 !important",
    lineHeight: '1.2 !important',
  },
  itemViewHeadImage: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover",
  },
  itemTitleWrap: {
    position: "absolute",
    zIndex: 3,
    width: "60vw",
    backgroundColor: "#fcd319",
    borderBottomRightRadius: "20rem",
    borderTopRightRadius: "20rem",
    marginBottom: "5rem",
    "@media (max-width: 1024px)": {
      height: "auto",
    },
    "@media (max-width: 600px)": {
      height: "auto !important",
      width: "70vw !important",
    },
  },
  itemTitleWrapBackDrop: {
    position: "absolute",
    zIndex: 2,
    width: "55vw",
    backgroundColor: "#ed1d65",
    borderBottomRightRadius: "20rem",
    borderTopRightRadius: "20rem",
    marginBottom: "3rem",
    "@media (max-width: 1024px)": {
      height: "5vh",
      marginBottom: "4rem",
    },
    "@media (max-width: 600px)": {
      width: "68vw",
      height: "6vw",
    },
  },
  itemOverlay: {
    width: "-webkit-fill-available",
    height: "80vh",
    backgroundColor: "rgb(0 0 0 / 75%)",
    position: "absolute",
    zIndex: 1,
  },
  itemImageWrap: {
    width: "100%",
    height: "80vh",
    overflow: "hidden",
    position: "absolute",
  },
  itemWrap: {
    width: "100%",
    height: "80vh",
    display: "flex",
    alignItems: "flex-end",
  },
  itemImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
  },
  itemImageFullSize: {
    width: " -webkit-fill-available",
  },
  infoSection: {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    padding: "2rem",
    "@media (max-width: 600px)": {
      padding: "5px",
    },
  },
  aboutItemWrap: {
    display: "flex",
    gap: "5vw",
    width: "100%",
    marginBottom: "5rem",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  aboutItemWrapSideLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "50%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  aboutItemWrapSideRight: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  aboutItemDescription: {
    color: "#ed1d65",
    "& .MuiTypography-root": {
      fontFamily: "Play !important",
      // fontSize: '12px',
      color: "#ed1d65",
      },
  },
  aboutItemPreviewButton: {
    width: "100%",
    border: 0,
    background: "none",
    overflow: "hidden",
    height: "-webkit-fill-available",
    "&:hover": {
      background: "none",
      boxShadow: "0 0 20px 2px rgb(237 29 101 / 24%)",
    },
  },
  aboutItemCircleWrap: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
  },
  aboutItemCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15vw",
    height: "15vw",
    backgroundColor: "#ed1d65",
    borderRadius: "20rem",
    padding: "1rem",
    "@media (max-width: 1024px)": {
      width: "18vw",
      height: "18vw",
    },
    "@media (max-width: 600px)": {
      width: "30vw",
      height: "30vw",
      "& .MuiTypography-root": {
        fontSize: "12px !important",
      },
    },
    "& .MuiTypography-root": {
      textTransform: "capitalize",
    },
  },
  aboutItemCircleInner: {
    padding: "0",
    flexDirection: "column",
    border: "1px solid white",
    borderRadius: "20rem",
    width: "15vw",
    height: "15vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    "@media (max-width: 1024px)": {
      width: "18vw",
      height: "18vw",
    },
    "@media (max-width: 600px)": {
      width: "28vw",
      height: "28vw",
    },
  },
  aboutItemDivider: {
    width: "5vw !important",
    borderColor: "#FFFFFF !important",
    borderWidth: "1px !important",
  },
  aboutItemButton: {
    width: "100%",
    borderRadius: "0 !important",
    padding: "0.5rem !important",
    boxShadow: "0 0 0 !important",
    backgroundColor: "#ed1d65 !important",
    fontFamily: "Play, sans-serif !important",
    fontWeight: "bold !important",
    textTransform: "capitalize !important",
    "&:hover": {
      backgroundColor: "#f78d35 !important",
    },
  },
  listsWrap: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    marginTop: "1rem",
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",
      display: "flex",
      flexDirection: "column",
    },
  },
  listBoxWrap: {
    gap: "1rem",
    width: "20vw",
    minHeight: "60vh",
    display: "flex",
    padding: "0.5rem",
    alignItems: "center",
    borderRadius: "2rem",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#fcd319",
    position: "absolute",
    zIndex: 1,
    "@media (max-width: 1680px)": {
      width: "22vw",
    },
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",
    },
  },
  listBoxWrapBackDrop: {
    width: "20vw",
    padding: "0.5rem",
    minHeight: "60vh",
    borderRadius: "2rem",
    marginBottom: "5rem",
    backgroundColor: "#ed1d65",
    position: "relative",
    marginTop: "1rem",
    marginLeft: "1rem",
    zIndex: 0,
    "@media (max-width: 1680px)": {
      width: "22vw",
    },
    "@media (max-width: 600px)": {
      width: "-webkit-fill-available",
    },
  },
  listBoxDivider: {
    width: "8vw !important",
    boxShadow: "0 0 5px #ED1D65",
    borderColor: "#ED1D65 !important",
    borderWidth: "2px !important",
    "@media (max-width: 600px)": {
      width: "20vw !important",
    },
  },
  listBoxHeader: {
    color: "#ED1D65 !important",
    fontWeight: "bold !important",
    // "@media (max-width: 1680px)": {
    //   fontSize: "18px !important",
    // },
  },
  listBoxBody: {
    color: "#ED1D65 !important",
    textAlign: "left",
    fontFamily: "Play, sans-serif !important",
  },
  galleryWrap: {
    backgroundColor: "#F95672 !important",
    color: "#FFFFFF !important",
  },
  galleryInnerWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
  },
  galleryImageListWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  previewPopper: {
    top: "0 !important",
    left: "0 !important",
    zIndex: 10,
    position: "fixed !important",
    transform: "translate(0px, 0px) !important",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(0 0 0 / 75%)",
  },
  previewBackground: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  previewBox: {
    padding: "3vw",
    background: "linear-gradient(134deg, #ff8ea2, #f95672)",
    borderRadius: "2rem",
    display: "flex",
    justifyContent: "center",
    maxHeight: "75vh",
  },
  previewBorderImage: {
    padding: "1rem",
    borderRadius: "1rem",
    backgroundColor: "white",
    width: "50vw",
    maxHeight: "100vh",
    overflowY: "auto",
  },
  closeButton: {
    top: "0 !important",
    right: "0rem",
    color: "#ff4040 !important",
    zIndex: 1400,
    alignSelf: "end",
    position: "absolute",
  },
  aboutItemPreviewImageWrap: {
    position: "absolute",
    width: "-webkit-fill-available",
    backgroundColor: "hsl(339deg 85% 52% / 75%)",
    color: "white",
    height: "5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  aboutItemPreviewImageOverlay: {},
  aboutItemPreviewImageText: {
    fontSize: "24px !important",
    fontFamily: '"Play", sans-serif !important',
    fontWeight: "bold !important",
  },
  workItemAudioPlayer: {},
  audioPlayerWrap: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem",
    borderRadius: "1rem",
    background: "linear-gradient(141deg, #f78d35, #ed1d65)",
    gap: "1rem",
    '@media (max-width: 1680px)':{
      flexDirection: "column",
      alignItems: 'center',
    },
  },
  audioPlayerImage: {
    width: "10vw",
    borderRadius: "1rem",
    '@media (max-width: 1680px)':{
      width: "30vw",
    },
  },
  audioPlayerSide1: {
    borderRadius: "1rem",
  },
  audioPlayerSide2: {
    display: "flex",
    flexDirection: "column",
    height: "-webkit-fill-available",
    width: "-webkit-fill-available",
  },
  audioPlayerTitle: {
    color: "white !important",
    fontSize: "24px !important",
  },
  audioPlayerDivider: {
    borderBottom: "2px solid white !important",
    padding: "5px",
  },
  backButton: {
    width: '12vw',
  },
});

export default useStyles;
