import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import useStyles from "./Contact.styles";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import emailjs from "emailjs-com";
import { ThemeProvider } from "@mui/styles";
import contactFormTheme from "./ContactFormTheme";
import "../contact/Contact.css";

// import SocialMediaButton1 from "../../uploads/general-assets/social-media-icons/Social_Media_Icon_Email_nobg.svg";
import SocialMediaButton1 from "../../../uploads/general-assets/social-media-icons/Social_Media_Icon_LinkedIn_nobg_pink.svg";
import SocialMediaButton2 from "../../../uploads/general-assets/social-media-icons/Social_Media_Icon_Instagram_nobg_pink.svg";

function Contact() {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!formData.message) validationErrors.message = "Message is required";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const templateParams = {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      };

      emailjs
        .send(
          "service_gdxeqk5", // Replace with your EmailJS service ID
          "template_dnmheqb", // Replace with your EmailJS template ID
          templateParams,
          "HEJ7GVVpLERT2gXC3" // Replace with your EmailJS user ID
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setFormData({ name: "", email: "", message: "" });
            alert("Message sent successfully!");
          },
          (error) => {
            console.error("FAILED...", error);
            alert("Message failed to send.");
          }
        );
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const validationErrors = {};
  //   if (!formData.name) validationErrors.name = "Name is required";
  //   if (!formData.email) validationErrors.email = "Email is required";
  //   if (!formData.message) validationErrors.message = "Message is required";

  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length === 0) {
  //     console.log("Form Submitted:", formData);
  //     // Add your submission logic here (e.g., send to API)
  //     setFormData({ name: "", email: "", message: "" });
  //   }
  // };

  return (
    // <ThemeProvider theme={contactFormTheme}>
    <Box className={classes.containerBox}>
      <Box className={classes.topHeader}>
        <Typography
          component="h1"
          sx={{
            padding: "1rem",
            paddingRight: "4rem",
          }}
        >
          Contact
        </Typography>
        <Divider className={classes.topHeaderDivider} />
      </Box>
      <Box className={classes.contactInfoWrap}>
        <Typography
          component="body"
          variant="body1"
          sx={{
            padding: "5rem",
            // paddingRight: '4rem',
          }}
        >
          If you have any questions or requests for me, I'd be happy to help!
          Please fill the form and I will do my best to respond to you within a
          few days.
            Any forms of spam, scam, threats, or any other sort of garbage will
            be left unresponded. I will assume you are a troll.

            Are you looking for or requesting for commissions? Please see here
            (TBA).
        </Typography>
      </Box>
      <Box className={classes.mainSection}>
        <Box className={classes.contactFormWrap}>
          <Typography
            variant="h2"
            sx={{
              marginBottom: 2,
              color: "#d81b60",
              fontWeight: "bold",
              textAlign: "left",
              "@media (max-width: 600px)": {
                textAlign: "center",
          
                  },
            }}
          >
            Inquire
          </Typography>
          <Box className={classes.contactForm}>
            <Box className={classes.contactBox1}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                sx={{ marginBottom: 2 }}
                className={classes.contactTextField}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                sx={{ marginBottom: 2 }}
                className={classes.contactTextField}
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
                sx={{ marginBottom: 2 }}
                className={classes.contactTextField}
              />
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                className={classes.contactSubmit}
                sx={{
                  backgroundColor: "#d81b60",
                  "&:hover": { backgroundColor: "#ad1457" },
                }}
              >
                Submit
              </Button>
            </Box>
            <Divider
              className={classes.sectionDivider}
              orientation="vertical"
            />
            <Box className={classes.contactBox2}>
              <Typography
                component="h3"
                sx={{
                  color: "#d81b60",
                  fontWeight: "bold",
                }}
              >
                Check out my Socials
              </Typography>
              <Typography
                component="body"
                variant="body1"
                sx={{
                  color: "#d81b60",
                }}
              >
                Social media stuff contacts
              </Typography>
              <Box className={classes.socialMediaWrap}>
                <Button
                  className={classes.socialMediaLink}
                  href="https://www.instagram.com/theyoungmightysoldier/"
                >
                  <img
                    src={SocialMediaButton2}
                    alt="Social Media Icon (Email)"
                    className={classes.socialMediaLinkImg}
                  />
                </Button>
                <Button className={classes.socialMediaLink}>
                  <img
                    src={SocialMediaButton1}
                    alt="Social Media Icon (Email)"
                    className={classes.socialMediaLinkImg}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    // </ThemeProvider>
  );
}

export default Contact;
