import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import useStyles from "./About.styles";
import "../about/About.css";

import MySkillIcon1 from "../../../uploads/general-assets/about-me/skill_1.png";
import MySkillIcon2 from "../../../uploads/general-assets/about-me/skill_2.png";
import MySkillIcon3 from "../../../uploads/general-assets/about-me/skill_3.png";
import MySkillIcon4 from "../../../uploads/general-assets/about-me/skill_4.png";
import MySkillIcon5 from "../../../uploads/general-assets/about-me/skill_5.png";

import profilePic from "../../../uploads/images/profile-pic.png";

function About() {
  const classes = useStyles();
  const isMobile = window.innerWidth <= 600;

  return (
    <Box className={classes.containerBox}>
      <Box className={classes.topHeader}>
        <Typography
          component="h1"
          sx={{
            padding: "1rem",
            paddingRight: "4rem",
          }}
        >
          About Me
        </Typography>
        <Divider className={classes.topHeaderDivider} />
      </Box>
      <Box className={classes.baseProfile}>
        <Box className={classes.baseProfileWrap}>
          <video
            autoPlay
            loop
            muted
            playsInline
            // style={{
            //   position: "absolute",
            //   top: 0,
            //   left: 0,
            //   // width: isMobile ? "40vw" : "60vw",
            //   // height: isMobile ? "40vw" : "60vw",
            //   objectFit: "cover",
            //   zIndex: 5,
            // }}
            className={classes.baseProfileVid}
          >
            {/* <source src="../../../uploads/general-assets/about-me/image-frame.webm" type="video/webm" /> */}
            <source
              src="https://ericathegreat.com/portfolio/assets/image-frame.webm"
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
          <Box className={classes.baseProfilePic}>
            <img
              src={profilePic}
              alt="temporary profile icon"
              style={{
                width: isMobile ? "115vw" : "60vw",
                clipPath: "circle(50% at 50% 50%)",
                position: "relative",
                marginLeft: "-17vw",
                marginTop: "-4vw",
              }}
            />
          </Box>
        </Box>
        <Box className={classes.baseProfileBio}>
          <Typography component="body" variant="body1">
            I am a versatile designer that focuses on graphic, video, and web
            design. My skill set has been influenced and inspired by my father,
            who is also a designer. I strive to create fresh and high-quality
            mixed with my passion and creativity. If you ever needed a designer
            that can create exceptional work, allow me to happily collaborate
            with you.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.mySkillsContainer}>
        <Box className={classes.mySkillsHeader}>
          <Typography
            component="h2"
            sx={{
              padding: "1rem",
              fontWeight: "bold",
            }}
          >
            My Skills
          </Typography>
          <Divider className={classes.topHeaderDivider} />
        </Box>
        <Box className={classes.mySkillsWrap}>
          <Box
            className={classes.mySkill}
            sx={{
              gridColumnStart: 1,
              gridRowStart: 1,
            }}
          >
            <img
              src={MySkillIcon1}
              alt="Graphic design skill"
              style={{
                width: isMobile ? "30vw" : "12vw",
              }}
            />
          </Box>
          <Box
            className={classes.mySkill}
            sx={{
              gridColumnStart: 3,
              gridRowStart: 1,
            }}
          >
            <img
              src={MySkillIcon2}
              alt="Audio skill"
              style={{
                width: isMobile ? "30vw" : "12vw",
              }}
            />
          </Box>
          <Box
            className={classes.mySkill}
            sx={{
              gridColumnStart: 5,
              gridRowStart: 1,
            }}
          >
            <img
              src={MySkillIcon3}
              alt="Singing skill"
              style={{
                width: isMobile ? "30vw" : "12vw",
              }}
            />
          </Box>
          <Box
            className={classes.mySkill}
            sx={{
              gridColumnStart: 2,
              gridRowStart: 2,
            }}
          >
            <img
              src={MySkillIcon4}
              alt="Video Editing skill"
              style={{
                width: isMobile ? "30vw" : "12vw",
              }}
            />
          </Box>
          <Box
            className={classes.mySkill}
            sx={{
              gridColumnStart: 4,
              gridRowStart: 2,
            }}
          >
            <img
              src={MySkillIcon5}
              alt="Web design skill"
              style={{
                width: isMobile ? "30vw" : "12vw",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
