import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerBox: {
    display: "flex",
    justifyContent: "center",
    width: "-webkit-fill-available",
  },
  homeMenuButton: {
    color: "white !important",
    padding: "0.5vw 1vw !important",
    borderRadius: "0 !important",
    clipPath: "polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%)",
    minWidth: "18vw !important",
    background: "linear-gradient(180deg, #F2778C, #F95672)",
    transition: "0.3s ease",
    fontFamily: "Play !important",
    fontWeight: "bold !important",
    textTransform: "capitalize !important",
    "& .MuiTypography-root": {
      lineHeight: 2,
    },
    "&:hover": {
      animation: "gradientChange 0.5s ease forwards",
      background: "linear-gradient(180deg, #FCD319, #FCA001)",
      // transition: "background-image 0.3s ease",
      //   transform: "scale(1.05)",
    },
    "::after ": {
      content: "'",
      color: "transparent",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: "0px",
      top: "0px",
      zIndex: "-1",
      borderRadius: "3px",
      backgroundColor: "#cc0323",
      transform: "skew(-30deg)",
    },
    "@media (max-width: 1680px)": {
      fontSize: "24px",
    },
  },
  "@keyframes gradientChange": {
    "0%": {
      background: "linear-gradient(180deg, #F2778C, #F95672)",
    },
    "100%": {
      background: "linear-gradient(180deg, #FCD319, #FCA001)",
    },
  },
  homeMenuLogo: {
    background: "linear-gradient(180deg, #F15331, #FF2D0C) !important",
    transition: "background 0.3s ease, transform 0.3s ease",
    "&:hover": {
      background: "linear-gradient(180deg, #ED1D65, #BF0042) !important",
    },
  },
  logo: {
    width: "10vw",
  },
  subMenu: {
    position: "absolute",
    zIndex: 2,
    width: "16vw",
    left: "2vw !important",
  },
  subMenuPaper: {
    borderRadius: 0,
    background: 0,
    margin: "0 !important",
  },
  subMenuItem: {
    background: "linear-gradient(180deg, #F2778C, #F95672)",
    fontFamily: "Play !important",
    textTransform: "capitalize !important",
    "&:hover": {
      background: "linear-gradient(180deg, #FCD319, #FCA001)",
      //   transform: "scale(1.05)",
    },
  },
  subMenuList: {
    padding: "0 !important",
  },
  subDivider: {
    width: "100% !important",
    margin: "0 !important",
    boxShadow: "0 0 5px 0px rgb(255, 255, 255)",
    borderColor: "rgb(255, 255, 255) !important",
    borderWidth: "1px !important",
    position: "relative",
    zIndex: 1,
  },
  mobileMenuContainer: {
    padding: 0,
    margin: 0,
    height: "auto",
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(180deg, #F15331, #FF2D0C) !important",
  },
  mobileMenuIcon: {
    color: "rgb(255, 255, 255)",
    padding: 0,
  },
  mobileLogo: {
    width: "30vw",
  },
  mobileHomeMenu: {
    width: "-webkit-fill-available",
    height: "fit-content",
  },
  mobileHomeMenuList: {
    padding: "2px",
  },
  mobileHomeMenuItem: {
    fontFamily: "Play !important",
    fontSize: "12px",
  },
  mobileHomeMenuText: {
    "& .MuiTypography-root": {
      fontFamily: "Play !important",
      fontSize: "12px",
    },
  },
  mobileHomeMenuBox: {
    background: "#f95672",
    height: "-webkit-fill-available",
  },
});

export default useStyles;
