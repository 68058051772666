import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    containerBox: {
        display: 'flex',
        flexDirection: 'column',
        // background: "linear-gradient(180deg, #FFFFFF, #ED1D65) !important",
        gap: '1rem',
        paddingBottom: '5rem',
        transition: "background 0.5s ease-in-out",
        width: '100%',
        minHeight: '70vh',
        height: '-webkit-fill-available',
    },
    topHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        "@media (max-width: 600px)": {
            alignItems: 'center',
            "& .MuiTypography-root": {
                padding: "5px",
                },
        },
    },
    topHeaderDivider: {
        width: '30vw !important', 
        // boxShadow: '0 0 5px #ED1D65', 
        // borderColor: '#ED1D65 !important',
        borderWidth: '1px !important', 
    },
    tabWrap: {
        // borderWidth: '4px',
    },
    tabTab: {
        fontFamily: '"Play", sans-serif !important',
        fontWeight: 'bold !important',
        fontSize: '20px !important',
        textTransform: 'capitalize !important',
        width: '100%',
        flex: 1,
        // '&.Mui-selected': {
        //     color: '#F78D35 !important',// Allows sx prop to control color
        // },
        "@media (max-width: 1680px)": {
            fontSize: "12px !important",
          },
          "@media (max-width: 600px)": {
            padding: "5px !important",
          },
            },
    tabDivider: {
        alignSelf: "center !important",
        height: "2vw !important",
        // borderColor: '#ED1D65 !important',
        borderWidth: '1px !important',
    },
    workWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        padding: '5vw',
        paddingTop: '1rem',
    },
});

export default useStyles;