import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    workWrapper: {
        gap: '1vw',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        },
    projectCard: {

    },
    hoverOverlay: {
        position: 'absolute',
        width: '100%', 
        height: '100%',
        zIndex: 10,
        top: 0,
        opacity: 0,
        transition: 'opacity 0.3s',
        backgroundColor: 'rgb(237 29 101 / 85%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "&:hover": {
            opacity: '1',
            transition: "0.3s",
            // transform: "scale(1.05)",
            // transition: "transform 0.3s ease-in-out",
          },
    },
    hoverText: {
        fontFamily: '"Play", sans-serif !important',
        fontWeight: 'bold !important',
        fontSize: '24px !important'
    },
    cardImage: {
        transform: "scale(1.00)",
        transition: "0.3s",
        "&:hover": {
            transform: "scale(1.05)",
            transition: "transform 0.3s ease-in-out",
          },
    },
});

export default useStyles;