import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerBox: {
    display: "flex",
    flexDirection: "column",
    background:
      "linear-gradient(180deg, #ED1D65, #ED1D65, #F2778C, #FFD1D9) !important",
    gap: "1rem",
    paddingBottom: "5rem",
  },
  topHeader: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    alignItems: "flex-end",
    "@media (max-width: 600px)": {
      alignItems: "center",
      "& .MuiTypography-root": {
        padding: "5px",
      },
    },
  },
  topHeaderDivider: {
    width: "30vw !important",
    boxShadow: "0 0 5px white",
    borderColor: "white !important",
    borderWidth: "1px !important",
  },
  baseProfile: {
    display: "flex",
    alignItems: "center",
    padding: "10vw",
    paddingTop: "2vw",
    gap: "5rem",
    "@media (max-width: 600px)": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    },
  },
  baseProfileWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  baseProfilePic: {
    border: "1px solid white",
    clipPath: "border-box",
    borderRadius: "40rem",
    width: "24vw",
    height: "24vw",
    background: "linear-gradient(180deg, #f95672, #F44336) !important",
    "@media (max-width: 600px)": {
      width: '43vw',
      height: '43vw',
        },
  },
  baseProfileVid: {
    position: 'absolute',
    width: '28.5vw',
    zIndex: 1,
    "@media (max-width: 600px)": {
      width: "50.5vw",
    },
  },
  baseProfileBio: {
    textAlign: "center",
  },
  mySkillsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  mySkillsWrap: {
    display: "grid",
    gridTemplateColumns: "10vw 10vw 10vw 10vw 10vw",
    gridTemplateRows: "1fr 1fr",
    "@media (max-width: 600px)": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "4vw",
    },
  },
  mySkillsHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5rem",
    "@media (max-width: 600px)": {
      marginBottom: "2rem",
    },
  },
  mySkill: {},
});

export default useStyles;
