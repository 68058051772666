import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Divider, useMediaQuery } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import mainLogo from "../../uploads/general-assets/logo.png";
// import { useNavigate } from "react-router-dom";
import useStyles from "./Header.styles";
import classNames from "classnames";
import "../header/Header.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

function Header(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // const handlePageHome = () => {
  //     navigate('../../pages/main/home');
  //   };

  //   const handlePageHome = () => {
  //     navigate('../../pages/main/home');
  //   };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const mobileMenu = (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        className={classes.mobileHomeMenuBox}
      >
        <List className={classes.mobileHomeMenuList}>
          <ListItem
            button
            onClick={props.handlePageHome}
            className={classes.mobileHomeMenuItem}
          >
            <ListItemText
              primary="Home"
              className={classes.mobileHomeMenuText}
            />
          </ListItem>
          <ListItem
            button
            onClick={props.handlePageAbout}
            className={classes.mobileHomeMenuItem}
          >
            <ListItemText
              primary="About"
              className={classes.mobileHomeMenuText}
            />
          </ListItem>
          <ListItem
            button
            onClick={props.handlePageWorks}
            className={classes.mobileHomeMenuItem}
          >
            <ListItemText
              primary="Works"
              className={classes.mobileHomeMenuText}
            />
          </ListItem>
          <ListItem
            button
            onClick={props.handlePageContact}
            className={classes.mobileHomeMenuItem}
          >
            <ListItemText primary="Contact" 
                          className={classes.mobileHomeMenuText}

            />
          </ListItem>
          <ListItem
            button
            onClick={props.handlePageCommission}
            className={classes.mobileHomeMenuItem}
          >
            <ListItemText
              primary="Commissions"
              className={classes.mobileHomeMenuText}
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  const desktopMenu = (
    <Box className={classes.containerBox}>
      <Button className={classes.homeMenuButton} onClick={props.handlePageHome}>
        <Typography component="body">Home</Typography>
      </Button>
      <Button
        className={classes.homeMenuButton}
        onClick={props.handlePageAbout}
      >
        <Typography component="body">About</Typography>
      </Button>
      <Button
        className={classNames(classes.homeMenuButton, classes.homeMenuLogo)}
        onClick={props.handlePageHome}
      >
        <img
          src={mainLogo}
          alt="Logo of my portfolio"
          className={classes.logo}
        />
      </Button>
      <Button
        className={classes.homeMenuButton}
        onClick={props.handlePageWorks}
      >
        <Typography component="body">Works</Typography>
      </Button>
      <Button
        className={classes.homeMenuButton}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Typography component="body">Contact</Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.subMenu}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper className={classes.subMenuPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  className={classes.subMenuList}
                >
                  <MenuItem
                    onClick={(event) => {
                      props.handlePageContact();
                      handleClose(event);
                    }}
                    className={classes.subMenuItem}
                  >
                    Contact Me
                  </MenuItem>
                  <Divider className={classes.subDivider} />
                  <MenuItem
                    onClick={(event) => {
                      props.handlePageCommission();
                      handleClose(event);
                    }}
                    className={classes.subMenuItem}
                  >
                    Commissions
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );

  return (
    // <Box className={classes.containerBox}>
    //   <Button className={classes.homeMenuButton} onClick={props.handlePageHome}>
    //     <Typography component="body">Home</Typography>
    //   </Button>
    //   <Button
    //     className={classes.homeMenuButton}
    //     onClick={props.handlePageAbout}
    //   >
    //     <Typography component="body">About</Typography>
    //   </Button>
    //   <Button
    //     className={classNames(classes.homeMenuButton, classes.homeMenuLogo)}
    //     onClick={props.handlePageHome}
    //   >
    //     <img
    //       src={mainLogo}
    //       alt="Logo of my portfolio"
    //       className={classes.logo}
    //     />
    //   </Button>
    //   <Button
    //     className={classes.homeMenuButton}
    //     onClick={props.handlePageWorks}
    //   >
    //     <Typography component="body">Works</Typography>
    //   </Button>
    //   <Button
    //     className={classes.homeMenuButton}
    //     // onClick={props.handlePageContact}
    //     ref={anchorRef}
    //     id="composition-button"
    //     aria-controls={open ? "composition-menu" : undefined}
    //     aria-expanded={open ? "true" : undefined}
    //     aria-haspopup="true"
    //     onClick={handleToggle}
    //   >
    //     <Typography component="body">Contact</Typography>
    //   </Button>
    //   <Popper
    //     open={open}
    //     anchorEl={anchorRef.current}
    //     role={undefined}
    //     placement="bottom-start"
    //     transition
    //     disablePortal
    //     className={classes.subMenu}
    //   >
    //     {({ TransitionProps, placement }) => (
    //       <Grow
    //         {...TransitionProps}
    //         style={{
    //           transformOrigin:
    //             placement === "bottom-start" ? "left top" : "left bottom",
    //         }}
    //       >
    //         <Paper className={classes.subMenuPaper}>
    //           <ClickAwayListener onClickAway={handleClose}>
    //             <MenuList
    //               autoFocusItem={open}
    //               id="composition-menu"
    //               aria-labelledby="composition-button"
    //               onKeyDown={handleListKeyDown}
    //               className={classes.subMenuList}
    //             >
    //               <MenuItem
    //                 onClick={(event) => {
    //                   props.handlePageContact(); // Navigate to the contact page
    //                   handleClose(event); // Close the submenu
    //                 }}
    //                 className={classes.subMenuItem}
    //               >
    //                 Contact Me
    //               </MenuItem>
    //               <Divider className={classes.subDivider} />
    //               <MenuItem
    //                 onClick={(event) => {
    //                   props.handlePageCommission(); // Navigate to the contact page
    //                   handleClose(event); // Close the submenu
    //                 }}
    //                 className={classes.subMenuItem}
    //               >
    //                 Commissions
    //               </MenuItem>
    //             </MenuList>
    //           </ClickAwayListener>
    //         </Paper>
    //       </Grow>
    //     )}
    //   </Popper>
    // </Box>
    <Box className={classes.containerBox}>
      {isMobile ? (
        <Box className={classes.mobileMenuContainer}>
          <Button
            className={classes.mobileHomeMenu}
            onClick={props.handlePageHome}
          >
            <img
              src={mainLogo}
              alt="Logo of my portfolio"
              className={classes.mobileLogo}
            />
          </Button>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            className={classes.mobileMenuIcon}
          >
            <MenuIcon
            className={classes.mobileMenuIcon}
            />
          </IconButton>
          {mobileMenu}
        </Box>
      ) : (
        desktopMenu
      )}
    </Box>
  );
}

export default Header;
